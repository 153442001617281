const getMobileDetect = (userAgent: string) => {
    const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i))
    const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
    const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i))
    const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i))
    const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i))

    const isMobile = (): boolean => Boolean(isAndroid() || isIos() || isOpera() || isWindows())
    const isDesktop = (): boolean => Boolean(!isMobile() && !isSSR())

    const summary = () =>
        `{isAndroid: ${isAndroid()}, isIos: ${isIos()}, isOpera: ${isOpera()}, isWindows: ${isWindows()}, isMobile: ${isMobile()}, isDesktop: ${isDesktop()}}`

    return {
        isMobile,
        isDesktop,
        isAndroid,
        isIos,
        isSSR,
        summary,
    }
}
export const useMobileDetect = () => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
    return getMobileDetect(userAgent)
}

export const userAgentLog = () => {
    const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
    return {
        userAgent: userAgent,
        userDeviceInfo: getMobileDetect(userAgent).summary(),
    }
}
