import { device } from '@util/responsive'
import { css } from 'styled-components'

export const ToasterStyle = css`
    // https://fkhadra.github.io/react-toastify/how-to-style#override-existing-css-classes
    .Toastify__toast {
        border-radius: 0;

        @media ${device.laptop} {
            border-radius: 12px;
        }
    }
    .Toastify__close-button {
        color: var(--toastify-close-button-color);
        opacity: 1;
    }
`
