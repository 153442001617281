import { useState, createContext, useContext, useEffect, ReactNode, useCallback, useMemo } from 'react'
import { CitySettingsResponse } from '@service/configuration.types'
import { formatDate, isRTLLocale } from '@util/config'
import { useRouter } from 'next/router'
import { useActiveCitySettings, useCityLanguages } from '@hooks/api/configuration'
import { useCityCode } from '@hooks/useCityCode'
import { City, CityCode, Country, LanguageUtsCode } from '@util/enums'
import { getCountryByCity } from '@util/localization'

type ConfigContextTypes = {
    citySettings: CitySettingsResponse | undefined
    lang: string | undefined
    cityCode: CityCode
    country: Country
    city: City
    isRTL: boolean
    cityLanguages: (LanguageUtsCode | undefined)[]
    formatDate: (date?: Date | number | string | null) => string
}

export const ConfigContext = createContext<ConfigContextTypes | null>(null)

export const LocaleConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { data: citySettings } = useActiveCitySettings()
    const { data: cityLanguages } = useCityLanguages()
    const { cityCode, city } = useCityCode()
    const { locale } = useRouter()
    const [lang, setLang] = useState<string>(locale || LanguageUtsCode.enUS)
    const isRTL = isRTLLocale(locale)

    const formatDateCallback = useCallback((date?: Date | number | string | null) => formatDate(date, isRTL), [isRTL])
    const country = useMemo(() => getCountryByCity(city), [city])

    useEffect(() => {
        setLang(locale || LanguageUtsCode.enUS)
    }, [locale])

    return (
        <ConfigContext.Provider
            value={{
                citySettings,
                lang,
                cityCode,
                country,
                city,
                isRTL,
                cityLanguages: cityLanguages ?? [],
                formatDate: formatDateCallback,
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

export const useLocaleConfig = (): ConfigContextTypes => useContext(ConfigContext) as ConfigContextTypes
