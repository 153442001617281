import { axiosify } from '@util/mock'
import { IdentityService } from './identity'
import {
    MarketingResponse,
    VerificationUploadResponse,
    VerificationVeriffUploadResponse,
    VerificationResponse,
    SaveUserProfileDataResponse,
    MeResponse,
    VerificationStatus,
    UploadedDocument,
    VerificationUploadStatus,
    Side,
    TouristData,
    RequiredDocumentsResponse,
} from './identity.types'
import { ServerLanguageCode } from '@util/enums'

const meResponse = {
    careemId: 'MockCareemId', //MockCareemId
    userId: 'ffc20739-f9a0-4bea-8a46-d1f3d494087b',
    email: 'hello@joinswapp.com',
    isBanned: false,
    firstName: 'Adam',
    lastName: 'Gabor',
    dateOfBirth: '1997-01-17',
    phoneNumber: '36301111111',
    preferredLanguage: ServerLanguageCode.EN,
    marketingMessagesAccepted: true,
    termsAndConditionsAcceptedAt: '2022-01-19T09:47:01.196664Z',
    isPhoneVerified: true,
    sendSms: false,
    isTouristKsa: true,
    isTouristUae: false,
    isVip: false,
    waNumber: '34234234',
    isWaEnabled: true,
    paymentRevampEnabled: true,
}

const mePatchResponse = {
    userId: 'ffc20739-f9a0-4bea-8a46-d1f3d494087b',
    isBanned: false,
    firstName: 'Adam',
    lastName: 'Gabor',
    dateOfBirth: '1997-01-17',
    phoneNumber: '36301111111',
    preferredLanguage: ServerLanguageCode.EN,
    marketingMessagesAccepted: true,
    termsAndConditionsAcceptedAt: '2022-01-19T09:47:01.196664Z',
    phoneVerified: true,
    sendSms: false,
    isTouristKsa: true,
    isTouristUae: false,
    isVip: false,
}

const verificationResponse: VerificationResponse = {
    currentDocument: null,
    overallStatus: VerificationUploadStatus.approved,
    uploadedDocuments: [
        {
            type: 'string',
            side: Side.front,
            status: VerificationStatus.approved,
        },
    ],
}

const requiredDocumentsResponse: RequiredDocumentsResponse = {
    requiredDocuments: {
        TOURIST: {
            ID_CARD: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            DRIVERS_LICENSE: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            INTERNATIONAL_DRIVERS_LICENSE: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            PASSPORT: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            VISA: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
        },
        RESIDENT: {
            ID_CARD: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            DRIVERS_LICENSE: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            INTERNATIONAL_DRIVERS_LICENSE: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            PASSPORT: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
            VISA: {
                isHidden: true,
                isMandatory: true,
                isTwoSided: true,
            },
        },
    },
}

const uploadResponse: VerificationUploadResponse = {
    userId: 0,
    imageKey: 'string',
    documentType: UploadedDocument.driversLicense,
    status: VerificationUploadStatus.approved,
}

const veriffUploadResponse: VerificationVeriffUploadResponse = {
    id: 'string',
    sessionUrl: 'string',
    documentType: UploadedDocument.passport,
}

const marketingResponse: MarketingResponse = {
    marketingMessagesAccepted: true,
}

const registerResponse: SaveUserProfileDataResponse = {
    success: true,
}

const updatePwresponse: MeResponse = {
    careemId: 'string',
    userId: 'string',
    email: 'string',
    isBanned: true,
    firstName: 'string',
    lastName: 'string',
    dateOfBirth: 'string',
    phoneNumber: 'string',
    preferredLanguage: ServerLanguageCode.EN,
    marketingMessagesAccepted: true,
    termsAndConditionsAcceptedAt: 'string',
    isPhoneVerified: true,
    sendSms: true,
    isTouristKsa: true,
    isTouristUae: false,
    isVip: false,
    waNumber: 'string',
    isWaEnabled: false,
    paymentRevampEnabled: true,
}

const editUserDataResponse: SaveUserProfileDataResponse = {
    success: true,
}

export const mockIdentityService: IdentityService = {
    getMe: async () => {
        return await axiosify(meResponse)
    },
    getVerificationStatus: async () => {
        return await axiosify(verificationResponse)
    },
    getRequiredDocuments: async () => {
        return await axiosify(requiredDocumentsResponse)
    },
    uploadDocument: async () => {
        return await axiosify(uploadResponse)
    },
    sendToVerification: async () => {
        return await axiosify(veriffUploadResponse)
    },

    sendMarketingConsent: async (_isAccepted) => {
        return await axiosify(marketingResponse)
    },
    loginForCareem: async () => {
        return await axiosify({ jwt: 'string', preferredLanguage: ServerLanguageCode.EN })
    },
    login: async (_loginData) => {
        return await axiosify({ jwt: 'string' })
    },

    registerWithEmail: async (_registerData) => {
        return await axiosify(registerResponse)
    },
    editUserData: async (_userData) => {
        return await axiosify(editUserDataResponse)
    },
    passwordReset: async (_pwresetData) => {
        return await axiosify({ status: 'string' })
    },
    updatePassword: async (_UpdateData) => {
        return await axiosify(updatePwresponse)
    },
    finalizePasswordReset: async () => {
        return await axiosify(meResponse)
    },
    loginWithSocial: async (_loginData) => {
        return await axiosify({ jwt: 'string' })
    },

    registerWithSocial: async (_registerData) => {
        return await axiosify(registerResponse)
    },

    updateUserPreferredLanguage: async (_preferredLanguage: ServerLanguageCode) => {
        return await axiosify(meResponse)
    },

    setTouristFlag: async (_touristData: TouristData) => {
        return await axiosify(mePatchResponse)
    },
}
