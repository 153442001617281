import { useAuth } from '@contexts/auth'
import { paymentService } from '@service/payment'
import {
    CardDetails,
    IPaymentMethodWithPreference,
    MadaStatus,
    PaymentMethodStatus,
    PaymentMethodType,
    PaymentProvider,
} from '@service/payment.types'
import { config } from '@util/config'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { isBlank } from '@util/functions'
import { useLocaleConfig } from '@contexts/config'
import { City } from '@util/enums'

type PaymentMethodContextValue = {
    paymentProvider: PaymentProvider
    paymentMethodType: PaymentMethodType
    preferredPaymentMethod?: IPaymentMethodWithPreference
    cardDetails?: CardDetails
    reloadCardDetails: () => Promise<void>
    areCardDetailsInserted: boolean
    threeDS: {
        threeDSUrl: string | undefined
        setThreeDSUrl: React.Dispatch<React.SetStateAction<string | undefined>>
        status: PaymentMethodStatus
    }
    madaStatus?: MadaStatus
}

const PaymentMethodContext = createContext<PaymentMethodContextValue | undefined>(undefined)

const findPreferredPaymentMethod = (paymentMethods: IPaymentMethodWithPreference[]) =>
    paymentMethods.find(({ isPreferred }) => isPreferred) ?? paymentMethods[0]

type PaymentMethodContextProviderProps = {
    children: ReactNode
}

const useAllowedPaymentMethods = (userId: string | undefined, isGetAllowedPaymentMethodsEnabled?: boolean) => {
    const { data: allowedPaymentMethods, refetch: refetchAllowedPaymentMethods } = useQuery(
        ['allowedPaymentMethods', userId],
        () => {
            if (!userId) return
            return paymentService.getAllowedPaymentMethods(userId)
        },
        {
            enabled: isGetAllowedPaymentMethodsEnabled,
            retry: 2,
            retryDelay: 10000,
        },
    )
    return { allowedPaymentMethods, refetchAllowedPaymentMethods }
}

const use3dsChallenge = (refetchAllowedPaymentMethods: () => void) => {
    const { user } = useAuth()
    const userId = user?.userId as string
    const [status, setStatus] = useState<PaymentMethodStatus>(PaymentMethodStatus.PENDING)
    const [threeDSUrl, setThreeDSUrl] = useState<string | undefined>(undefined)
    useEffect(() => {
        setStatus(PaymentMethodStatus.PENDING)
    }, [threeDSUrl])
    useQuery(
        ['paymentMethods', threeDSUrl],
        async () => {
            const status = await paymentService
                .getAllowedPaymentMethods(userId)
                .then((res) => res.data?.paymentMethods?.at(0)?.status)
            setStatus(status ?? PaymentMethodStatus.PENDING)
            if (status === PaymentMethodStatus.SUCCESS || status === PaymentMethodStatus.FAILED) {
                refetchAllowedPaymentMethods()
            }
        },
        { enabled: !!threeDSUrl && status === PaymentMethodStatus.PENDING, refetchInterval: 1000 },
    )
    return { threeDSUrl, setThreeDSUrl, status }
}

export const PaymentMethodContextProvider = ({ children }: PaymentMethodContextProviderProps) => {
    const { user, isCareem } = useAuth()
    const userId = user?.userId ?? undefined
    const { city } = useLocaleConfig()
    const isCheckoutEnabled = !isBlank(config.CHECKOUT_API_KEY)

    const [paymentProvider, setPaymentProvider] = useState<PaymentProvider>(
        isCareem ? PaymentProvider.CPAY : PaymentProvider.CHECKOUT,
    )
    const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(
        isCareem ? PaymentMethodType.CPAY : PaymentMethodType.CARD,
    )
    const [preferredPaymentMethod, setPreferredPaymentMethod] = useState<IPaymentMethodWithPreference | undefined>(
        undefined,
    )

    const isGetAllowedPaymentMethodsEnabled = !!userId && isCheckoutEnabled
    const { allowedPaymentMethods, refetchAllowedPaymentMethods } = useAllowedPaymentMethods(
        userId,
        isGetAllowedPaymentMethodsEnabled,
    )
    const threeDS = use3dsChallenge(refetchAllowedPaymentMethods)

    useEffect(() => {
        if (!allowedPaymentMethods?.data) {
            setPreferredPaymentMethod(undefined)
            return
        }

        const { paymentProvider, paymentMethodType, paymentMethods } = allowedPaymentMethods.data

        setPaymentProvider(paymentProvider)
        setPaymentMethodType(paymentMethodType)
        if (paymentMethods.length) {
            setPreferredPaymentMethod(findPreferredPaymentMethod(paymentMethods))
        }
    }, [allowedPaymentMethods, isCareem])

    const checkoutCardDetails = useMemo(() => {
        if (
            paymentProvider !== PaymentProvider.CHECKOUT ||
            paymentMethodType !== PaymentMethodType.CARD ||
            !preferredPaymentMethod ||
            preferredPaymentMethod.status === PaymentMethodStatus.FAILED
        ) {
            return undefined
        }

        const { brand, last4Digits, expiryMonth, expiryYear } = preferredPaymentMethod
        return {
            brand,
            expiryMonth,
            expiryYear,
            last4DigitsOfCardNr: last4Digits,
        }
    }, [paymentProvider, paymentMethodType, preferredPaymentMethod])

    const cardDetails = useMemo(() => {
        if (paymentProvider === PaymentProvider.CHECKOUT) return checkoutCardDetails
        return undefined
    }, [paymentProvider, checkoutCardDetails])

    const areCardDetailsInserted = useMemo(() => {
        if (!cardDetails) return false
        return (
            !isBlank(cardDetails.brand) &&
            !isBlank(cardDetails.last4DigitsOfCardNr) &&
            cardDetails.expiryMonth > 0 &&
            cardDetails.expiryYear > 0
        )
    }, [cardDetails])

    const reloadCardDetails = useCallback(async () => {
        if (isGetAllowedPaymentMethodsEnabled) {
            await refetchAllowedPaymentMethods()
        }
    }, [refetchAllowedPaymentMethods, isGetAllowedPaymentMethodsEnabled])

    const { data: madaStatus } = useQuery(
        ['madaStatus', preferredPaymentMethod?.paymentMethodId],
        () => paymentService.getPaymentMethodMadaStatus(preferredPaymentMethod?.paymentMethodId ?? 0),
        { enabled: !!preferredPaymentMethod?.paymentMethodId && city === City.riyadh },
    )

    return (
        <PaymentMethodContext.Provider
            value={{
                paymentProvider,
                paymentMethodType,
                preferredPaymentMethod,
                cardDetails,
                reloadCardDetails,
                areCardDetailsInserted,
                threeDS,
                madaStatus: madaStatus?.data?.status,
            }}
        >
            {children}
        </PaymentMethodContext.Provider>
    )
}

export const usePaymentMethodContext = () => {
    const context = useContext(PaymentMethodContext)

    if (context === undefined) {
        throw new Error(`PaymentMethodContext can't be undefined!`)
    }

    return context
}
