import { cityLanguageConfigService, configurationService } from '@service/configuration'
import { ProductType } from '@service/configuration.types'
import { useQuery } from 'react-query'
import { useGlobalState } from '@contexts/global'
import { useCityCode } from '@hooks/useCityCode'
import { minutesToMilliseconds } from 'date-fns'
import { getUtsLanguageCodeByCityCodeAndLanguageIsoCode } from '@util/localization'
import { CityCode, LanguageISOCode } from '@util/enums'
import { defaultLocale } from '@util/config'

export const getActiveCitySettings = (cityCode: string, product: ProductType) => {
    return configurationService.getCitySettings(cityCode, product).then((res) => res.data)
}
export const getActiveCitySettingsKey = (cityCode: string, product: ProductType) => [
    'citySettings',
    { cityCode, product },
]

export const useActiveCitySettings = (city?: CityCode, product?: ProductType) => {
    const {
        bookingState: { productType },
    } = useGlobalState()
    const { cityCode } = useCityCode()

    return useQuery(
        getActiveCitySettingsKey(city || cityCode, product || productType),
        () => getActiveCitySettings(city || cityCode, product || productType),
        { staleTime: minutesToMilliseconds(5) },
    )
}

export const getCityLanguages = async (cityCode: CityCode) => {
    const response = await cityLanguageConfigService.getCityLanguages(cityCode)
    return (
        response?.data?.translations
            ?.map((lang: { language: string }) => {
                const langISOCode = lang.language.toLocaleLowerCase() as LanguageISOCode
                return getUtsLanguageCodeByCityCodeAndLanguageIsoCode(cityCode, langISOCode)
            })
            .filter((lang) => !!lang) ?? [defaultLocale]
    )
}
export const getCityLanguagesKey = (cityCode: string) => ['cityLanguages', cityCode]
export const useCityLanguages = () => {
    const { cityCode } = useCityCode()
    return useQuery(getCityLanguagesKey(cityCode), () => getCityLanguages(cityCode), {
        staleTime: minutesToMilliseconds(5),
    })
}
