import { Currency } from './configuration.types'
import { ChargeTypes, CityCode, PaymentIntentStatus } from '@util/enums'

export enum CardNetwork {
    REGULAR = 'REGULAR',
    MADA = 'MADA',
}

export type UserBillingAddressResponse = {
    externalCustomerId: string
    careemId: string
    billingName: string
    vatId: string
    addressLine1: string
    addressLine2: string
    city: string
    region: string
    zipCode: string
    country: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
}

export type UserBillingAddress = {
    id: string
    type: string
    billingName: string
    vatId: string
    addressLine1: string
    addressLine2: string
    city: string
    region: string
    zipCode: string
    country: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
}

export type Address = {
    id: string
    type: AddressType
}

export enum AddressType {
    DELIVERY = 'DELIVERY',
    RETURN = 'RETURN',
}

export type AddressResponse = {
    addressType: AddressType
    comments: string
    lat: number
    long: number
    building: string
    addressLine1: string
    addressLine2: string
    city: string
    region: string
    zipCode: string
    country: string
    createdBy: string
    createdAt: string
    updatedBy: string
    updatedAt: string
}

export type CancelBookingResponse = {
    surchargeAmount: number
    status: string
}

export type CancellationFeeResponse = {
    surchargeAmount: number
}

export type UserEditBillingAddress = {
    billingName: string
    vatId?: string
    addressLine1?: string
    addressLine2?: string
    city: string
    region?: string
    zipCode?: string
    country: string
    emailAddress?: string
}

export enum InvoiceStatus {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SUCCEEDED = 'SUCCEEDED',
    REFUNDED = 'REFUNDED',
}

export type CareemStatusResponse = {
    careemInvoiceId: string
    invoiceStatus: InvoiceStatus
}

export type PaymentIntentStatusResponse = {
    paymentIntentStatus: PaymentIntentStatus
    responseCode: number
    responseI18nKey: string
}

export type SelectedDate = {
    from: Date
    to: Date
}

export type DailyPriceCalculation = {
    externalListingIds: [number | string]
    cityCode: CityCode
    handoverTime: Date | string
    handbackTime: Date | string
    couponCode?: string
    includeExtraInsurance?: boolean
    includeDelivery?: boolean
    includeSecondaryDriver?: boolean
}

export type MonthlyPriceCalculation = {
    externalListingIds: [number | string]
    cityCode: string
    startDate: Date | string
    endDate: Date | string | null
    couponCode?: string
}

export type DailyPriceCalculationResult = {
    currency: Currency
    cityCode: string
    handoverTime: Date | string
    handbackTime: Date | string
    couponCode?: string
    freeCancellationEndsAt: string
    includeDelivery: boolean
    includeExtraInsurance: boolean
    includeSecondaryDriver: boolean
    rentalPeriodDays: number
    listings: PriceDetails[]
}

export type MonthlyPriceCalculationResult = {
    currency: Currency
    cityCode: string
    startDate: Date | string
    endDate: Date | string | null
    freeCancellationEndsAt: string
    rentalPeriodDays: number
    listings: PriceDetails[]
}

export type BookingPriceCalculation = {
    handbackTime: Date | string
}

export type BookingStatusResponse = {
    status: string
    surchargeAmount: number
}

export type ActivePriceCalculation = {
    allowedKmsPerDay: number
    cityCode: CityCode
    dailyPrice?: number
    monthlyPrice?: number
    depositAmount: number
    externalListingId: number
    extraInsuranceFee: number
    id: number | string
    surchargeExtraKmsPerUnit: number
}

export type PriceDetails = {
    externalListingId: number
    vatPercentage: number
    dailyPrice: number
    deliveryFee: number
    totalDeposit: number
    firstCharge: number
    totalRentalFee: number
    totalRentalFeeAfterDiscount: number
    discountAmount: number
    allowedKmsPerDay?: number
    allowedKmsPerYear?: number
    surchargeExtraKmsPerUnit: number
    couponDetails: {
        code: string
        valid: boolean
        errors: [
            {
                type: string
                parameter: any
            },
        ]
        description: string
        discountAmount: number
        discountRate: number
        discountAmountLimit: number
        minRentalPeriod: number
        maxRentalPeriod: number
        minRentalFee: number
        minMonthlyRentalFee: number
    }
    extraInsuranceFee?: number
    totalExtraInsuranceFee: number
    secondaryDriverFee: number
    totalSecondaryDriverFee: number
    depositMonthsToCharge?: number
    monthlyBasePrice?: number
    monthlyPrice?: number
    periodPrice?: number
    serviceFee: number
}

export type PaymentBooking = {
    id: number
    externalBookingId: number
    listingDescription: string
    cityCode: string
    currency: string
    handoverTime: string
    handbackTime: string
    rentalPeriodDays: number
    priceDetails: PriceDetails
    status: string
    freeCancellationEndsAt: string
    includeExtraInsurance: boolean
    includeDelivery: boolean
    includeSecondaryDriver: boolean
}

export type CustumerInvoiceDocument = {
    id: number
    invoiceDate: string
    currency: string
    totalPrice: number
}

export type CouponDetails = {
    code: string
    valid: boolean
    errors: [
        {
            type: string
            parameter: any
        },
    ]
    description: string
    discountAmount: number
    discountRate: number
    discountAmountLimit: number
    minRentalPeriod: number
    maxRentalPeriod: number
    minRentalFee: number
    minMonthlyRentalFee: number
}

export type BookingPriceCalculationResponse = {
    differenceToOriginal: {
        dailyPrice: number
        totalDeposit: number
        firstCharge: number
        totalRentalFee: number
        totalRentalFeeAfterDiscount: number
        discountAmount: number
        allowedKmsPerDay: number
        surchargeExtraKmsPerUnit: number
        extraInsuranceFee: number
        totalExtraInsuranceFee: number
        secondaryDriverFee: number
        totalSecondaryDriverFee: number
        deliveryFee: number
        totalDeliveryFee: number
    }
    newPrice: {
        externalListingId: number
        vatPercentage: number
        dailyPrice: number
        totalDeposit: number
        firstCharge: number
        totalRentalFee: number
        totalRentalFeeAfterDiscount: number
        discountAmount: number
        allowedKmsPerDay: number
        surchargeExtraKmsPerUnit: number
        couponDetails: CouponDetails
        extraInsuranceFee: number
        totalExtraInsuranceFee: number
        secondaryDriverFee: number
        totalSecondaryDriverFee: number
        deliveryFee: number
        totalDeliveryFee: number
    }
}

export enum CardBrand {
    AMEX = 'AMEX',
    DINERS = 'DINERS',
    DISCOVER = 'DISCOVER',
    JCB = 'JCB',
    MASTERCARD = 'MASTERCARD',
    UNIONPAY = 'UNIONPAY',
    VISA = 'VISA',
    UNKNOWN = 'UNKNOWN',
}

export type CardDetails = {
    brand: CardBrand
    last4DigitsOfCardNr: string
    expiryYear: number
    expiryMonth: number
}

export enum PaymentProvider {
    CPAY = 'CPAY',
    CHECKOUT = 'CHECKOUT',
}

export enum PaymentMethodType {
    CPAY = 'CPAY',
    CARD = 'CARD',
}

export enum PaymentMethodStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export type IPaymentMethodWithPreference = {
    paymentMethodId: number
    isPreferred: boolean
    status: PaymentMethodStatus
    [propName: string]: any
}

export type CardDetailsWithPreference = IPaymentMethodWithPreference & {
    brand: CardBrand
    last4Digits: string
    expiryYear: number
    expiryMonth: number
}

export type AllowedPaymentMethods = {
    paymentProvider: PaymentProvider
    paymentMethodType: PaymentMethodType
    paymentMethods: IPaymentMethodWithPreference[]
}

export type MonthlyHandbackDateUpdateResponse = {
    originalBooking: {
        bookingId: number
        listingDescription: string
        cityCode: CityCode
        currency: Currency
        startDate: string
        endDate: string
        rentalPeriodDays: number
        priceDetails: PriceDetails
        balance: number
        freeCancellationEndsAt: string
        earliestPossibleEndDate: string
    }
    updatedBooking: {
        bookingId: number
        listingDescription: string
        cityCode: string
        currency: string
        startDate: string
        endDate: string
        rentalPeriodDays: number
        priceDetails: PriceDetails
        balance: number
        freeCancellationEndsAt: string
        earliestPossibleEndDate: string
    }
}

export type PaymentMethodMadaStatus = {
    status: MadaStatus
}

export enum MadaStatus {
    MADA_AUTHORIZED = 'MADA_AUTHORIZED',
    MADA_NOT_AUTHORIZED = 'MADA_NOT_AUTHORIZED',
    NOT_MADA = 'NOT_MADA',
}

export type BookingCharge = {
    date: string
    chargeType: ChargeTypes
    amount: number
    comment: string
    documentKeys: string[]
    chargeId: number
}
