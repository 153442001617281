import { useQuery } from 'react-query'
import { PaymentMethodContextProvider } from './paymentMethodContext'
import { AuthProvider } from './auth'
import { SearchProvider } from './search'
import React, { ReactNode } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { LocaleConfigProvider } from './config'
import { WarningProvider } from './warnigContext'
import { JitsuProvider, createClient, usePageView } from '@jitsu/nextjs'
import { config, isDev } from '@util/config'
import { GlobalProvider } from './global'
import DiscountCodeProvider from './discountCode'
import { CommonProvider } from './common'
import FreshChatProvider from './freschatContext'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { getGrowthbookFeatures } from '@service/frontend'
import { minutesToMilliseconds } from 'date-fns'

export const jitsuClient = createClient({
    tracking_host: config.JITSU_TRACKING_HOST,
    key: config.JITSU_CLIENT_DATA_KEY,
    log_level: config.JITSU_LOG_LEVEL,
})

export const growthbook = new GrowthBook({
    enableDevMode: isDev,
})

export const ContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    usePageView(jitsuClient)

    useQuery('getFeatures', getGrowthbookFeatures, {
        onSuccess(data) {
            if (data?.features) growthbook.setFeatures(data.features)
        },
        staleTime: minutesToMilliseconds(10),
        cacheTime: minutesToMilliseconds(10),
    })

    return (
        <GrowthBookProvider growthbook={growthbook}>
            <ReactQueryDevtools initialIsOpen={false} />
            <GlobalProvider>
                <LocaleConfigProvider>
                    <JitsuProvider client={jitsuClient}>
                        <AuthProvider>
                            <FreshChatProvider>
                                <SearchProvider>
                                    <DiscountCodeProvider>
                                        <PaymentMethodContextProvider>
                                            <CommonProvider>
                                                <WarningProvider>{children}</WarningProvider>
                                            </CommonProvider>
                                        </PaymentMethodContextProvider>
                                    </DiscountCodeProvider>
                                </SearchProvider>
                            </FreshChatProvider>
                        </AuthProvider>
                    </JitsuProvider>
                </LocaleConfigProvider>
            </GlobalProvider>
        </GrowthBookProvider>
    )
}
