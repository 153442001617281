import { ProductType } from '@service/configuration.types'
import { ReactNode, createContext, useCallback, useContext, useState } from 'react'
import { BookingActionKind, useGlobalState } from './global'

type CommonContextType = {
    changeProductTo: (productType: ProductType) => void
    handlerFullScreenLoaderState: (loaderValue: boolean) => void
    showFullScreeLoader: boolean
}

const CommonContext = createContext<CommonContextType>({
    changeProductTo: () => null,
    handlerFullScreenLoaderState: () => null,
    showFullScreeLoader: false,
})

export const CommonProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { bookingDispatch } = useGlobalState()
    const [showFullScreeLoader, setShowFullScreenLoader] = useState(false)

    const changeProductTo = useCallback(
        (productType: ProductType) => {
            if (!productType) return

            bookingDispatch({
                type: BookingActionKind.CHANGE_PRODUCT_TYPE,
                value: productType,
            })
        },
        [bookingDispatch],
    )
    const handlerFullScreenLoaderState = useCallback((loaderValue: boolean) => {
        setShowFullScreenLoader(loaderValue)
    }, [])

    return (
        <CommonContext.Provider
            value={{
                changeProductTo,
                handlerFullScreenLoaderState,
                showFullScreeLoader,
            }}
        >
            {children}
        </CommonContext.Provider>
    )
}

export function useCommon() {
    return useContext(CommonContext)
}
