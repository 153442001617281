import { AxiosResponse } from 'axios'
import { isMock } from './config'

export const getMockProxy: MockProxy = (realObject, mockObject) => {
    if (!mockObject) return realObject
    const mockHandler = {
        get(_, prop) {
            if ((typeof mockObject === 'object' && prop in mockObject) || prop in realObject) {
                if (isMock) return mockObject[prop]
                if (realObject[prop] === undefined) {
                    return mockObject[prop]
                }
            }
            return realObject[prop]
        },
    }
    return new Proxy(realObject, mockHandler)
}

export const axiosify = <T>(payload: T): Promise<AxiosResponse<T>> => {
    return new Promise((res) => {
        res({ data: payload } as AxiosResponse<T>)
    })
}

export type MockProxy = <T>(realObject: Partial<T>, mockObject: T) => T
