import { defaultCityCode } from '@util/config'
import { useRouter } from 'next/router'
import { getCityByCityCode, getCityCodeByCity } from '@util/localization'
import { City, CityCode } from '@util/enums'

export const useCityCode = () => {
    const {
        query: { city: cityParam },
    } = useRouter()
    let cityCode: CityCode = defaultCityCode
    let city: City = getCityByCityCode(defaultCityCode)
    if (cityParam && typeof cityParam === 'string') {
        cityCode = getCityCodeByCity(cityParam)
        city = cityParam as City
    }
    return { city, cityCode }
}
