import axios from 'axios'
import { config, defaultCityCode } from '@util/config'
import { getMockProxy } from '@util/mock'
import { mockCityLanguageConfigService, mockConfigurationService } from './configuration.mock'
import { CityDetails, CitySettingsResponse, ProductType, productTypeToUrlParam } from './configuration.types'
import { ServiceResponse } from '../types/global'

export const CONFIGURATION_API_URL = `${config.BASE_URL}/configuration`
export const IDENTITY_API_URL = `${config.BASE_URL}/identity`

export type ConfigurationService = {
    getCitySettings: (cityCode: string, product?: string) => ServiceResponse<CitySettingsResponse>
    getTranslations: (locale: string) => ServiceResponse<{ id: string; locale: string; text: string }[]>
    getLegalDocuments: (locale: string) => ServiceResponse<{ id: string; locale: string; text: string }[]>
}

const realConfigurationService: Partial<ConfigurationService> = {
    getCitySettings: async (cityCode = defaultCityCode, product = 'DAILY') => {
        return await axios.get(
            `${CONFIGURATION_API_URL}/products/${productTypeToUrlParam(
                product as ProductType,
            )}/cities/${cityCode}/settings/active`,
        )
    },

    getTranslations: async (locale = defaultCityCode) => {
        return await axios.get(`${CONFIGURATION_API_URL}/products/DAILY/translations/locales/${locale}`)
    },

    getLegalDocuments: async (locale = defaultCityCode) => {
        return await axios.get(`${CONFIGURATION_API_URL}/products/DAILY/translations/locales/${locale}/legal-documents`)
    },
}

export const configurationService: ConfigurationService = getMockProxy(
    realConfigurationService,
    mockConfigurationService,
)

export type CityLanguageConfigService = {
    getCityLanguages: (cityCode: string) => ServiceResponse<CityDetails>
}
const realCityLanguageConfigService: CityLanguageConfigService = {
    getCityLanguages: async (cityCode = defaultCityCode) => {
        return await axios.get(`${CONFIGURATION_API_URL}/cities/${cityCode}`)
    },
}

export const cityLanguageConfigService: CityLanguageConfigService = getMockProxy(
    realCityLanguageConfigService,
    mockCityLanguageConfigService,
)
