import { ProductType } from '@service/configuration.types'
import { logToBackend } from '@service/frontend'
import { LogLabels, LogTypes } from '@util/enums'
import { useCallback } from 'react'
import { userAgentLog } from './detectDevice'

export const formatConditionally = (value?: unknown, prefix = '', suffix = '') =>
    `${value ? `${prefix}${value}${suffix}` : ''}`

export const useLog = () => {
    const logger = useCallback((msg: string, type: LogTypes, label: LogLabels, product?: ProductType) => {
        if (!msg || !type || !label) return

        const message = product ? `(${product})${msg}` : msg

        logToBackend(message, type, {
            label,
            userAgent: userAgentLog().userAgent,
            deviceInfo: userAgentLog().userDeviceInfo,
        })
    }, [])

    return logger
}
