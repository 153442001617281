import React, { createContext, ReactNode, useContext, useState } from 'react'

type DiscountContextType = {
    discountCode: string
    changeDiscount: (discountCode: string) => void
}

const DiscountContext = createContext<DiscountContextType>({
    discountCode: '',
    changeDiscount: () => null,
})

const DiscountCodeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [discountCode, setDiscountCode] = useState('')

    const changeDiscount = (discountCode: string) => {
        setDiscountCode(discountCode)
    }

    return <DiscountContext.Provider value={{ discountCode, changeDiscount }}>{children}</DiscountContext.Provider>
}

export function useDiscountContext() {
    return useContext(DiscountContext)
}

export default DiscountCodeProvider
