import { useCityCode } from '@hooks/useCityCode'
import { useFreshChat } from '@hooks/useFreshChat'
import { getFreshchatWidgetByCityProductAndLanguage } from '@util/config'
import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react'
import { useAuth } from './auth'
import { useRouter } from 'next/router'
import { useGlobalState } from './global'

type FreshChatContextType = {
    setProductType: (value: string) => void
}

const FreshChatContext = createContext<FreshChatContextType>({
    setProductType: () => null,
})

const FreshChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { city } = useCityCode()
    const { isCareem } = useAuth()
    const { locale } = useRouter()
    const { bookingState } = useGlobalState()
    const { freshChatInitObject, setProperties } = useFreshChat()

    const widgetGuid = useMemo(
        () => getFreshchatWidgetByCityProductAndLanguage(city, bookingState.productType, locale || 'en'),
        [bookingState.productType, city, locale],
    )

    useEffect(() => {
        // Freshchat initialization
        if (typeof window !== 'undefined' && window?.fcWidget) {
            window.fcWidget.destroy()
            window.fcWidget.init({ ...freshChatInitObject, ...(widgetGuid ? { widgetUuid: widgetGuid } : {}) })

            window.fcWidget.on('widget:destroyed', function () {
                window.fcWidget.init({ ...freshChatInitObject, ...(widgetGuid ? { widgetUuid: widgetGuid } : {}) })
                window.fcWidget.show()
            })

            return () => {
                window.fcWidget.off('widget:destroyed')
            }
        }

        return
    }, [freshChatInitObject, widgetGuid])

    useEffect(() => {
        const value = `${typeof isCareem !== 'undefined' && 'isCareem:' + isCareem + ';'}${
            locale && 'locale:' + locale + ';'
        }${city && 'city:' + city + ';'}${bookingState.productType && 'product:' + bookingState.productType + ';'}`
        setProperties(value)
    }, [isCareem, locale, city, setProperties, bookingState.productType])

    return <FreshChatContext.Provider value={{ setProductType: () => null }}>{children}</FreshChatContext.Provider>
}

export function useFreshChatContext() {
    return useContext(FreshChatContext)
}

export default FreshChatProvider
