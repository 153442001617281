import { useJitsu } from '@jitsu/nextjs'
import { MeResponse } from '@service/identity.types'
import { useCallback } from 'react'
import { ProductType } from '@service/configuration.types'
import { isServer } from '@util/config'
import { FacebookEventType } from '@util/enums'

export enum EventType {
    ButtonClicked = 'button_clicked',
    BookingStarted = 'booking_started',
    PaymentStarted = 'payment_started',
}

export enum ButtonClickedButtonName {
    PaymentStarted = 'payment_started',
    HighlightBooking = 'highlight_booking',
    ExtendBooking = 'extend_booking',
    CareemContactRefresh = 'careem_contact_refresh',
    LeaseRedirect = 'lease_redirect',
}

export const useTracking = () => {
    const jitsu = useJitsu()

    return {
        trackEvent: useCallback((eventType: EventType, payload = {}) => jitsu.track(eventType, payload), [jitsu]),
        trackBookingStarted: useCallback(
            (productType: ProductType) => jitsu.track(EventType.BookingStarted, { product_type: productType }),
            [jitsu],
        ),
        trackPaymentStarted: useCallback(
            (productType: ProductType) => jitsu.track(EventType.PaymentStarted, { product_type: productType }),
            [jitsu],
        ),
        trackButtonClicked: useCallback(
            (buttonName: ButtonClickedButtonName) => jitsu.track(EventType.ButtonClicked, { button_name: buttonName }),
            [jitsu],
        ),
        trackExtendBooking: useCallback(
            () => jitsu.track(EventType.ButtonClicked, { buttonName: ButtonClickedButtonName.ExtendBooking }),
            [jitsu],
        ),
        trackUserLogin: useCallback(
            (user: MeResponse) => jitsu.id({ user_id: user.userId, is_careem_user: !!user.careemId }, true),
            [jitsu],
        ),
        trackUserLogout: useCallback(() => jitsu.id({ user_id: null, is_careem_user: null }, true), [jitsu]),
        trackCareemContactRefreshClicked: useCallback(
            () => jitsu.track(EventType.ButtonClicked, { button_name: ButtonClickedButtonName.CareemContactRefresh }),
            [jitsu],
        ),
        trackLeaseRedirect: useCallback(
            () => jitsu.track(EventType.ButtonClicked, { button_name: ButtonClickedButtonName.LeaseRedirect }),
            [jitsu],
        ),
        trackFacebook: useCallback((event: FacebookEventType, payload = {}) => {
            if (!window || typeof window?.fbq !== 'function' || isServer) return
            window.fbq('track', event, payload)
        }, []),
    }
}
