import useMountEffect from '@hooks/useMountEffect'
import { AxiosResponse } from 'axios'
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { UseMutateAsyncFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { identityService } from 'src/service/identity'
import { useTracking } from '@hooks/useTracking'
import { EditUserData, MeResponse, StandaloneLoginParams } from '@service/identity.types'
import { useFeature, useFeatureProvider } from '@hooks/useFeatures'
import { axios, localAxios } from '@util/axios'
import { useRouter } from '@hooks/useRouter'
import { socialLogin } from '@service/frontend'
import { LogLabels, LogTypes } from '@util/enums'
import { getUserLocale } from '@util/localization'
import { useLog } from '@hooks/useLog'
import { StatusCodes } from 'http-status-codes'
import { secondsToMilliseconds } from 'date-fns'
import { CUSTOMER_UI_PAYMENT_TEST } from '@util/featureFlags'

export type AuthContextType = {
    login: (loginData: StandaloneLoginParams) => Promise<AxiosResponse>
    logoutUser: () => Promise<void>
    user: MeResponse | undefined
    isCareem: boolean
    socialLoginFunction: UseMutateAsyncFunction<
        AxiosResponse<{
            status: string
        }>,
        unknown,
        SocialLogin,
        unknown
    >
    refetchUserData: () => void
    refreshUserData: () => void
    isUserFetched: boolean
    onLoginSuccess: (redirectUrl?: string) => void
    isLoggedIn: boolean
    updateUserData: UseMutateAsyncFunction<Partial<AxiosResponse<any, any>>, unknown, EditUserData, unknown>
    isEnabledNewBookingFlow: boolean
}
type SocialLogin = {
    email: string
    accessToken: string
    isGoogle: boolean
}

export enum AppState {
    PENDING = 'PENDING',
    LOADING = 'LOADING',
    LOGGED_IN = 'LOGGED_IN',
    LOGGED_OUT = 'LOGGED_OUT',
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const logger = useLog()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState<MeResponse | null>(null)
    const [initialLoading, setInitialLoading] = useState(true)
    const [isFetchDetails, setIsFetchDetails] = useState(false)
    const { identifyUser } = useFeatureProvider()
    const { enabled: enabledNewFlowFlag } = useFeature(CUSTOMER_UI_PAYMENT_TEST)
    const { trackUserLogin, trackUserLogout } = useTracking()
    const queryClient = useQueryClient()
    const {
        query: { city, redirect },
        push,
    } = useRouter()

    const isEnabledNewBookingFlow = useMemo(() => {
        if (!user) return enabledNewFlowFlag
        return !!user?.paymentRevampEnabled
    }, [enabledNewFlowFlag, user])

    const redirectUrl = useMemo(() => {
        return redirect ? `${redirect}` : `/${city}/cars`
    }, [city, redirect])

    const { refetch: refetchUserData, isFetched: isUserFetched } = useQuery(
        ['userLogin', user?.userId || ''],
        () => identityService.getMe(),
        {
            onSuccess: ({ data }) => {
                if (data) {
                    trackUserLogin(data)
                    setUser(data)
                    identifyUser(data)
                    setIsLoggedIn(true)
                    setIsFetchDetails(false)
                } else {
                    setIsLoggedIn(false)
                    setUser(null)
                    trackUserLogout()
                }
            },
            onError: () => {
                setIsLoggedIn(false)
                setUser(null)
                trackUserLogout()
            },
            cacheTime: secondsToMilliseconds(10),
            retry: false,
            enabled: initialLoading || isLoggedIn || isFetchDetails,
        },
    )

    const { mutateAsync: socialLoginFunction } = useMutation({
        mutationFn: async ({ email, accessToken, isGoogle }: SocialLogin) => {
            return await socialLogin(accessToken, email, isGoogle)
        },
        onSuccess: () => {
            setIsLoggedIn(true)
            refetchUserData()
        },
    })

    const { mutateAsync: updateUserData } = useMutation({
        mutationFn: async ({ firstName, lastName, email, waNumber, isWaEnabled, phoneNumber }: EditUserData) => {
            return await identityService.editUserData({
                firstName,
                lastName,
                email,
                waNumber,
                isWaEnabled,
                phoneNumber,
            })
        },
    })

    const { mutateAsync: refreshUserData } = useMutation({
        mutationFn: () => identityService.getMe(),
        onSuccess: (data) => {
            if (data.data) setUser(data.data)
        },
    })

    const login = useCallback(
        async (loginParams) => {
            const response = await localAxios.post('/auth/login', loginParams)

            if (response.status === 200) {
                setIsLoggedIn(true)
                await refetchUserData()
            }

            return response
        },
        [refetchUserData],
    )

    const onLoginSuccess = useCallback(
        async (url: string = redirectUrl) => {
            try {
                const userData = user ?? (await identityService.getMe())?.data
                const userPreferredLanguage = userData?.preferredLanguage
                if (userPreferredLanguage) {
                    const userLocale = await getUserLocale(userPreferredLanguage, city as string)
                    push(url, undefined, { locale: userLocale })
                } else {
                    push(url)
                }
            } catch (error) {
                logger(`Error during login data sync: ${error}`, LogTypes.error, LogLabels.Login)
            }
        },
        [logger, push, city, user, redirectUrl],
    )

    const logoutUser = useCallback(async (): Promise<void> => {
        const res = await localAxios.post('/auth/logout')
        if (res.status === 200) {
            setIsLoggedIn(false)
            setUser(null)
            trackUserLogout()
            await queryClient.invalidateQueries('user')
            push(`/${city}/cars`)
        }
    }, [city, push, queryClient, trackUserLogout])

    const isCareem = useMemo(() => !!user?.careemId, [user])

    useMountEffect(() => {
        setInitialLoading(false)
    })

    useEffect(() => {
        const responseInterceptor = axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response
            },
            async (error) => {
                if (error.response.status === StatusCodes.FORBIDDEN && user) {
                    setIsFetchDetails(true)
                }
                return Promise.reject(error)
            },
        )

        return () => {
            axios.interceptors.response.eject(responseInterceptor)
        }
    }, [user])

    const authState = useMemo(() => {
        return {
            login,
            logoutUser,
            user,
            isCareem,
            socialLoginFunction,
            refetchUserData,
            isUserFetched,
            refreshUserData,
            onLoginSuccess,
            isLoggedIn,
            updateUserData,
            isEnabledNewBookingFlow,
        }
    }, [
        login,
        logoutUser,
        user,
        isCareem,
        socialLoginFunction,
        refetchUserData,
        refreshUserData,
        isUserFetched,
        onLoginSuccess,
        isLoggedIn,
        updateUserData,
        isEnabledNewBookingFlow,
    ])

    return (
        <AuthContext.Provider value={authState as AuthContextType}>{!initialLoading && children}</AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextType => {
    return useContext(AuthContext)
}
