import { axios } from '@util/axios'
import { config } from '@util/config'
import { TimeSlotsForBusinessHoursResponse, TimeSlotsForListingResponse } from './availability.types'
import { ServiceResponse } from 'src/types/global'
import { CityCode } from '@util/enums'

export const AVAILABILITY_API_URL = `${config.BASE_URL}/availability`

export const availabilityService = {
    getTimeSlotsByListingId: async (
        listingId: number | string,
        date: string,
        cityCode: CityCode,
    ): ServiceResponse<TimeSlotsForListingResponse> => {
        return axios.get(
            `${AVAILABILITY_API_URL}/time-slot-availability/listing/${listingId}?date=${date}&cityCode=${cityCode}`,
        )
    },
    getTimeSlotsByBookingId: async (
        bookingId: number | string,
        date: string,
        cityCode: CityCode,
    ): ServiceResponse<TimeSlotsForListingResponse> => {
        return axios.get(
            `${AVAILABILITY_API_URL}/time-slot-availability/customer/booking/${bookingId}?date=${date}&cityCode=${cityCode}`,
        )
    },
    getTimeSlotsForBusinessHoursByCity: async (
        cityCode: CityCode,
    ): ServiceResponse<TimeSlotsForBusinessHoursResponse> => {
        return axios.get(`${AVAILABILITY_API_URL}/time-slot-availability/business-hours?cityCode=${cityCode}`)
    },
}
