import { CitySettingsResponse } from '@service/configuration.types'
import { addHours, format, parse, addMinutes, isSameDay, setMinutes, setHours, formatISO } from 'date-fns'
import { cookieName, defaultOpenHoursEndTime, defaultOpenHoursStartTime, timeFormat, timeFormatWithMs } from './config'
import { TranslationPrefix } from './enums'
import cookie from 'cookie'
import { AxiosError } from 'axios'
import { ErrorResponse } from '@service/common.types'

export interface DeliveryTime {
    key: number
    time: string
    date: Date
    hidden: boolean
}

import { PickerTime } from 'src/types/Time'

export const convertToTranslationKey = (value: string, type: TranslationPrefix) => {
    return `${type}.${value?.toLowerCase()}`
}

export const roundToNearest30 = (date = new Date(), openHoursStartTime?: string, roundFromNow = false) => {
    const openDateTime = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        +(openHoursStartTime || defaultOpenHoursStartTime).split(':')[0],
        +(openHoursStartTime || defaultOpenHoursStartTime).split(':')[1],
        0,
    )

    const fromDateTime =
        date.getTime() < openDateTime.getTime()
            ? openDateTime
            : isSameDay(date, new Date()) || roundFromNow
            ? date
            : openDateTime

    const minutes = 30
    const ms = 1000 * 60 * minutes

    return new Date(Math.ceil(fromDateTime.getTime() / ms) * ms)
}

export const DEFAULT_EXTRA_HOURS_D2D = 2
export const DEFAULT_EXTRA_HOURS_SELF_PICKUP = 0
export const getDefaultExtraHours = (isD2D: boolean) =>
    isD2D ? DEFAULT_EXTRA_HOURS_D2D : DEFAULT_EXTRA_HOURS_SELF_PICKUP
export const displayTimeRange = (date: Date, extraHours?: number) => {
    try {
        const extraTime = extraHours ? ` - ${format(addHours(date, extraHours), timeFormat)}` : ''
        return `${format(date, timeFormat)}${extraTime}`
    } catch {
        return ''
    }
}

export const generateTimesForTimePicker = (
    citySettings: CitySettingsResponse | undefined,
    isD2D: boolean,
    start?: Date | null,
    end?: Date | null,
) => {
    const now = new Date()

    const startDayName = format(now, 'EEEE').toUpperCase()

    const startHour = citySettings?.businessHours[startDayName]?.start || defaultOpenHoursStartTime
    const endHours = citySettings?.businessHours[startDayName]?.end || defaultOpenHoursEndTime

    const startDate = isD2D || !start ? parse(startHour, 'HH:mm:ss', now) : start
    const endDate = isD2D || !end ? parse(endHours, 'HH:mm:ss', startDate || now) : end

    const timeList: Date[] = []
    for (let d = startDate; d <= endDate; d = addMinutes(d, 30)) {
        timeList.push(new Date(d))
    }

    return timeList
}

export const extractTimeStringFromDate = (date = new Date()) => {
    const hours = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

    return `${hours}:${minutes}`
}

export const createRangeFromDate = (date: Date, fromToFormat: boolean) =>
    fromToFormat ? format(date, timeFormat) + ' - ' + format(addHours(date, 2), timeFormat) : format(date, timeFormat)

export const createDateTimeFromTimeSlot = (timeSlot: string) => {
    const now = new Date()
    const hours = timeSlot.split(':')[0]
    const minutes = timeSlot.split(':')[1]
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), +hours, +minutes, 0)
}

export const getDefaultDateWithHour = (hour: number) => {
    const date = new Date()
    date.setHours(hour)
    return date
}

export const parseTime = (referenceDate: Date, timeString: string) => parse(timeString, 'HH:mm:ss', referenceDate)

export const displayTimeSlot = (timeSlot: string) => format(parse(timeSlot, timeFormatWithMs, new Date()), timeFormat)

export const convertDateAndTimeToOneDate = (date?: Date | null, time?: Date | null) => {
    return date && time ? setMinutes(setHours(date, time.getHours()), time.getMinutes()) : new Date()
}

export const downloadFileHandler = (url: string) => {
    const element = document.createElement('a')
    element.href = url
    element.download = url
    element.setAttribute('target', '_blank')
    document.body.appendChild(element)
    element.click()
}

export const createArrayRange = (length: number) => Array.from(Array(length).keys())

export const getValueFromArrayQueryType = <T>(queryType: string[] | string | undefined): T[] => {
    let returnValue: T[] = []
    if (queryType && typeof queryType !== 'string') {
        returnValue = queryType.map((cT) => cT.toUpperCase()) as unknown as T[]
    } else if (queryType && typeof queryType === 'string') {
        returnValue = [queryType.toUpperCase()] as unknown as T[]
    }

    return returnValue
}

export const isBlank = (text?: string) => !text || text.trim().length === 0

export const filterTimeslotsBetweenTimes = (timeSlots: PickerTime[], fromTime: string, toTime?: string) => {
    const now = new Date()

    return timeSlots.filter((item: PickerTime) => {
        const isBeforeFromTime = parse(item.start, 'HH:mm', now).getTime() < parse(fromTime, 'HH:mm', now).getTime()
        const isAfterToTime =
            toTime && parse(item.start, 'HH:mm', now).getTime() > parse(toTime, 'HH:mm', now).getTime()

        return isBeforeFromTime || isAfterToTime
    })
}

export function createQueryParams(params: Record<string, string | string[] | number | undefined>) {
    const queryParams = new URLSearchParams()
    Object.entries(params)?.forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value?.forEach((v) => queryParams.append(key, v))
        } else if (value) {
            queryParams.append(key, value.toString())
        }
    })

    return queryParams
}

export const createCookieSerialization = (jwt: string, maxAgeInMs?: number) => {
    const ONE_DAY_IN_MS = maxAgeInMs || 60 * 60 * 24 * 1000

    return cookie.serialize(cookieName, jwt, {
        sameSite: 'lax',
        maxAge: ONE_DAY_IN_MS,
        httpOnly: true,
        secure: true,
        path: `/`,
    })
}

export const getISODate = (fromDate: Date | null, fromTime: Date | null) => {
    const newDate = formatISO(fromDate as Date, { representation: 'date' })
    const newTime = fromTime && formatISO(fromTime as Date, { representation: 'time' })
    return fromTime && new Date(`${newDate}T${newTime}`)?.toISOString()
}

export const getMessageFromException = (error: unknown) =>
    ((error as AxiosError).response?.data as ErrorResponse) || (error as AxiosError).message || ''

export const isHTML = (str: string) => {
    const pattern = /<\/?[a-z][\s\S]*>/i
    return pattern.test(str)
}
