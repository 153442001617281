import { useRouter as useNextRouter } from 'next/router'
import { useLocaleConfig } from '@contexts/config'
import { Pages } from '@util/enums'
import { useCallback, useMemo } from 'react'
export const useRouter = () => {
    const router = useNextRouter()
    const {
        asPath,
        pathname,
        query: { listingsId, bookingId },
    } = router
    const { city } = useLocaleConfig()

    const pushWithCity: (typeof router)['push'] = (url, as, options) => {
        if (typeof url === 'string') {
            return router.push(`/${city}${url}`, as, options)
        } else {
            url.pathname = `/${city}/${url.pathname}`
            return router.push(url, as, options)
        }
    }

    const replaceWithCity: (typeof router)['replace'] = (url, as, options) => {
        if (typeof url === 'string') {
            return router.replace(`/${city}${url}`, as, options)
        } else {
            url.pathname = `/${city}/${url.pathname}`
            return router.replace(url, as, options)
        }
    }

    const currentPathArray = useMemo(
        () =>
            asPath
                ?.split('/')
                .filter((x) => x)
                .splice(1),
        [asPath],
    )

    const isPage = useCallback(
        (siteName: Pages) => {
            if (
                siteName === Pages.carDetails &&
                listingsId &&
                currentPathArray[currentPathArray.length - 1] === listingsId
            )
                return true
            if (siteName === Pages.browse && pathname.split('/').pop() === 'cars') return true

            if (
                siteName === Pages.postBooking &&
                bookingId &&
                currentPathArray[currentPathArray.length - 1] === bookingId
            )
                return true

            if (siteName === Pages.summary && pathname.split('/').pop() === Pages.summary) return true
            if (siteName === Pages.myRental && pathname.split('/').pop() === Pages.myRental) return true
            if (siteName === Pages.documents && pathname.split('/').pop() === Pages.documents) return true
            if (siteName === Pages.profileDetail && pathname.split('/').pop() === Pages.profileDetail) return true

            return false
        },
        [bookingId, currentPathArray, listingsId, pathname],
    )

    return { ...router, pushWithCity, replaceWithCity, isPage, currentPathArray }
}
