import { JSONValue, useFeature as useGrowthbookFeature, useGrowthBook } from '@growthbook/growthbook-react'
import { MeResponse } from '@service/identity.types'
import { useCallback } from 'react'

interface Feature<T> {
    id: string
    value: T | null
    enabled: boolean
}

export const useFeature = <T extends JSONValue>(feature: string): Feature<T> => {
    const value = useGrowthbookFeature<T>(feature)

    return {
        id: value?.ruleId || feature,
        value: value?.value || null,
        enabled: value?.on || false,
    }
}

export const useFeatureProvider = () => {
    const growthbook = useGrowthBook()

    return {
        identifyUser: useCallback(
            (user: Partial<MeResponse>) => {
                growthbook?.setAttributes({
                    id: user.userId,
                    email: user.email,
                    careemId: user.careemId,
                })
            },
            [growthbook],
        ),
        isReady: growthbook?.ready,
    }
}
