module.exports = {
    debug: false,
    i18n: {
        defaultLocale: 'en-DXB',
        locales: ['en-DXB', 'ar-DXB', 'en-AUH', 'ar-AUH'],
    },
    localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales',
    reloadOnPrerender: false,
    ns: ['common'],
    defaultNS: 'common',
}
