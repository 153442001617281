import { config } from '@util/config'
import { LogTypes } from '@util/enums'
import axios, { AxiosResponse } from 'axios'
import { localAxios } from '../util/axios'

export const logOut = () => {
    return localAxios.get(`/auth/logout`)
}

export const socialLogin = (
    accessToken: string,
    email: string,
    isGoogle: boolean,
): Promise<AxiosResponse<{ status: string }>> => {
    return localAxios.post(`/auth/social-login`, {
        accessToken,
        email,
        isGoogle,
    })
}

export const careemAuth = (query: string) => {
    return localAxios.get(`/auth/careem/handler?${query}`)
}

export const logToBackend = (msg: string, type: LogTypes, additional?: any) => {
    return localAxios.post(`/logger/`, {
        msg,
        type,
        additional,
    })
}

export const getGrowthbookFeatures = async () => {
    const { data } = await axios.get(config.GROWTHBOOK_ENDPOINT)
    return data
}
