import {
    MePatchResponse,
    RequiredDocumentsResponse,
    Side,
    TouristData,
    UploadedDocument,
} from '@service/identity.types'
import { axios } from '@util/axios'
import { config } from '../util/config'
import { getMockProxy } from '../util/mock'
import { mockIdentityService } from './identity.mock'
import {
    MeResponse,
    VerificationUploadResponse,
    VerificationResponse,
    VerificationVeriffUploadResponse,
    SaveUserProfileDataResponse,
    MarketingResponse,
    RegisterWithEmailData,
    EditUserData,
    RegisterWithSocialData,
} from './identity.types'
import { ServiceResponse } from '../types/global'
import { CareemAuthLoginType, CityCode, Country, ServerLanguageCode } from '@util/enums'

export const IDENTITY_API_URL = `${config.BASE_URL}/identity`

export type IdentityService = {
    getMe: () => ServiceResponse<MeResponse>
    getVerificationStatus: (country: Country) => ServiceResponse<VerificationResponse>
    getRequiredDocuments: (country: Country) => ServiceResponse<RequiredDocumentsResponse>
    uploadDocument: (
        uploadDocumentType: UploadedDocument,
        side: Side,
        formData: FormData,
        country: Country,
    ) => ServiceResponse<VerificationUploadResponse>
    sendToVerification: (
        id: string,
        sessionUrl: string,
        documentType: UploadedDocument,
    ) => ServiceResponse<VerificationVeriffUploadResponse>
    sendMarketingConsent: (isAccepted: boolean) => ServiceResponse<MarketingResponse>
    loginForCareem: (
        code: string,
        country: string,
        loginType: CareemAuthLoginType,
    ) => ServiceResponse<{ jwt: string; preferredLanguage: ServerLanguageCode }>
    login: (email: string, password: string) => ServiceResponse<{ jwt: string }>
    loginWithSocial: (email: string, accessToken: string, isGoogle: boolean) => ServiceResponse<{ jwt: string }>
    registerWithEmail: (registerData: RegisterWithEmailData) => ServiceResponse<SaveUserProfileDataResponse>
    registerWithSocial: (registerData: RegisterWithSocialData) => ServiceResponse<SaveUserProfileDataResponse>
    passwordReset: (email: string, city: CityCode) => ServiceResponse<any>
    finalizePasswordReset: (token: string, newPassword: string) => ServiceResponse<MeResponse>
    editUserData: (userData: EditUserData) => ServiceResponse<any>
    updatePassword: (oldPassword: string, newPassword: string) => ServiceResponse<MeResponse>
    updateUserPreferredLanguage: (languageCode: ServerLanguageCode, jwt?: string) => ServiceResponse<MeResponse>
    setTouristFlag: (touristData: TouristData) => ServiceResponse<MePatchResponse>
}

const realIdentityService: Partial<IdentityService> = {
    getMe: async () => {
        return await axios.get(`${IDENTITY_API_URL}/me/details`, {
            withCredentials: true,
        })
    },
    getVerificationStatus: async (country: Country) => {
        return await axios.get(`${IDENTITY_API_URL}/me/documents/${country}/status`)
    },
    getRequiredDocuments: async (country: Country) => {
        return await axios.get(`${IDENTITY_API_URL}/me/documents/${country}/required-documents`)
    },
    uploadDocument: async (uploadDocumentType: UploadedDocument, side: Side, formData, country: Country) => {
        return await axios.post(`${IDENTITY_API_URL}/me/documents/${country}/${uploadDocumentType}/${side}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
    },
    sendMarketingConsent: async (isAccepted) => {
        return await axios.patch('/identity/me/details/marketing-consent', { marketingMessagesAccepted: isAccepted })
    },

    loginForCareem: async (authCode: string, country: string, loginType: CareemAuthLoginType) => {
        return await axios.post(`${IDENTITY_API_URL}/login/careem`, {
            authCode,
            country,
            loginType,
        })
    },

    login: async (email: string, password: string) => {
        return await axios.post(`${IDENTITY_API_URL}/login/siteuser`, {
            email,
            password,
        })
    },

    loginWithSocial: async (email: string, accessToken: string, isGoogle: boolean) => {
        return await axios.post(`${IDENTITY_API_URL}/login/social`, {
            email,
            accessToken,
            isGoogle,
        })
    },
    registerWithEmail: async (registerData: RegisterWithEmailData) => {
        return await axios.post(`${IDENTITY_API_URL}/registration`, registerData)
    },

    registerWithSocial: async (registerData: RegisterWithSocialData) => {
        return await axios.post(`${IDENTITY_API_URL}/registration/social`, registerData)
    },

    passwordReset: async (email: string, city: CityCode) => {
        return await axios.post(`${IDENTITY_API_URL}/registration/password-reset`, {
            email,
            city,
        })
    },
    finalizePasswordReset: async (token: string, newPassword: string) => {
        return await axios.patch(`${IDENTITY_API_URL}/registration/password-reset`, {
            token,
            newPassword,
        })
    },
    editUserData: async (userData: EditUserData) => {
        return await axios.patch(`${IDENTITY_API_URL}/me/details`, userData)
    },

    updatePassword: async (oldPassword: string, newPassword: string) => {
        return await axios.patch(`${IDENTITY_API_URL}/me/details/password`, { oldPassword, newPassword })
    },
    updateUserPreferredLanguage: async (preferredLanguage: ServerLanguageCode, jwt?: string) => {
        const options = jwt ? { headers: { Authorization: `Bearer ${jwt}` } } : undefined
        return await axios.patch(`${IDENTITY_API_URL}/me/details/preferred-language`, { preferredLanguage }, options)
    },
    setTouristFlag: async (touristData: TouristData) => {
        return await axios.patch(`${IDENTITY_API_URL}/me/details/tourist-flags`, touristData)
    },
}

export const identityService: IdentityService = getMockProxy(realIdentityService, mockIdentityService)
