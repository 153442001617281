import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { ContinueActionButton } from '@global/ContinueActionButton'

export const ErrorFallback = ({ resetErrorBoundary }) => {
    const { t } = useTranslation()
    return (
        <Wrapper>
            <div>
                <h1>{t('globalErrorMessage', 'Looks like some error occurred')}</h1>
                <ContinueActionButton
                    mobileFix
                    text={t('globalErrorButtonText', 'Reload page')}
                    onClick={resetErrorBoundary}
                />
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    div {
        margin-top: 30px;
    }
`
