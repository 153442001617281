import { createContext, useContext, ReactNode, useReducer, Dispatch } from 'react'

type WarningState = {
    show: boolean
}

type WarningContextTypes = {
    warningState: WarningState
    dispatch: Dispatch<any>
}

export const WarningContext = createContext<WarningContextTypes | null>(null)

const DISPLAY_WARNING = 'DISPLAY_WARNING'

const INITAL_STATE = {
    show: false,
}

const warningReducer = (state: WarningState, action: any) => {
    switch (action.type) {
        case DISPLAY_WARNING:
            return { ...state, show: action.value }
        default:
            return state
    }
}

export const displayWarning = (value: boolean) => {
    return {
        type: DISPLAY_WARNING,
        value,
    }
}

export const WarningProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [warningState, dispatch] = useReducer(warningReducer, INITAL_STATE)

    return (
        <WarningContext.Provider
            value={{
                warningState,
                dispatch,
            }}
        >
            {children}
        </WarningContext.Provider>
    )
}

export const useWarning = (): WarningContextTypes => useContext(WarningContext) as WarningContextTypes
