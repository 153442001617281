import { useEffect, useState } from 'react'

export const useMediaQuery = (query: string): boolean => {
    const [doesMatch, onSetDoesMatch] = useState(false)

    useEffect(() => {
        const onUpdateMatch = ({ matches }) => {
            onSetDoesMatch(matches)
        }

        const matchMedia = window.matchMedia(query)

        if (matchMedia.addEventListener) {
            matchMedia.addEventListener('change', onUpdateMatch)
            onUpdateMatch(matchMedia)
        }

        return () => {
            if (matchMedia.removeEventListener) {
                matchMedia.removeEventListener('change', onUpdateMatch)
            }
        }
    }, [query, onSetDoesMatch])

    return doesMatch
}
