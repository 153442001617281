import { CityCode, ServerLanguageCode } from '@util/enums'

export enum Side {
    oneSided = '',
    front = 'FRONT',
    back = 'BACK',
}

export enum VerificationUploadStatus {
    submitted = 'SUBMITTED',
    approved = 'APPROVED',
    declined = 'DECLINED',
}

export enum UploadedDocument {
    id = 'ID_CARD',
    visa = 'VISA',
    passport = 'PASSPORT',
    driversLicense = 'DRIVERS_LICENSE',
    internationalDriversLicense = 'INTERNATIONAL_DRIVERS_LICENSE',
    other = 'OTHER',
}

export enum VerificationStatus {
    awaitingSubmission = 'AWAITING_SUBMISSION',
    pending = 'PENDING',
    submitted = 'SUBMITTED',
    approved = 'APPROVED',
    declined = 'DECLINED',
    resubmissionRequested = 'RESUBMISSION_REQUESTED',
}

export type MeResponse = {
    careemId: string
    userId: string
    email: string
    isBanned: boolean
    firstName: string
    lastName: string
    dateOfBirth: string
    phoneNumber: string
    preferredLanguage: ServerLanguageCode
    marketingMessagesAccepted: boolean
    termsAndConditionsAcceptedAt: string
    isPhoneVerified: boolean
    sendSms: boolean
    isTouristUae: boolean
    isTouristKsa: boolean
    isVip: boolean
    waNumber?: string
    isWaEnabled: boolean
    paymentRevampEnabled: boolean | null
}

export type MePatchResponse = {
    userId: string
    firstName: string
    lastName: string
    dateOfBirth: string
    phoneNumber: string
    preferredLanguage: ServerLanguageCode
    marketingMessagesAccepted: boolean
    termsAndConditionsAcceptedAt: string
    phoneVerified: boolean
    sendSms: boolean
    isTouristUae: boolean
    isTouristKsa: boolean
    isVip: boolean
}

export type UploadedDocuments = {
    type: string
    side: Side
    status: VerificationStatus
}

export type RequireDocumentState = { isMandatory: boolean; isHidden: boolean; isTwoSided: boolean }

export type RequireDocument = {
    TOURIST: Partial<Record<UploadedDocument, RequireDocumentState>>
    RESIDENT: Partial<Record<UploadedDocument, RequireDocumentState>>
}

export type RequiredDocumentsResponse = {
    requiredDocuments: RequireDocument
}

export type VerificationResponse = {
    currentDocument: UploadedDocuments | null
    overallStatus: VerificationUploadStatus
    uploadedDocuments: UploadedDocuments[]
}

export type VerificationUploadResponse = {
    userId: number
    imageKey: string
    documentType: UploadedDocument
    status: VerificationUploadStatus
}

export type VerificationVeriffUploadResponse = {
    id: string
    sessionUrl: string
    documentType: UploadedDocument
}

export type SaveUserProfileDataResponse = {
    success: boolean
}

export type UserProfileData = {
    email: string
    firstName: string
    lastName: string
    marketingNotification: boolean
    phoneNumber: string
    phonePrefix: string
}

export type MarketingResponse = {
    marketingMessagesAccepted: boolean
}

export type RegisterWithEmailData = {
    email: string
    password: string
    passwordSalt: string
    firstName: string
    lastName: string
    marketingMessagesAccepted: boolean
    phoneNumber: string
    preferredLanguage: ServerLanguageCode
    termsAndConditionsAcceptedAt: string
    isPhoneVerified: boolean
    sendSms: boolean
    dateOfBirth: string
    city: CityCode
    country: string
}

export type RegisterWithSocialData = {
    email: string
    accessToken: string
    firstName: string
    lastName: string
    marketingMessagesAccepted: boolean
    phoneNumber: string
    city: CityCode
    country: string
    preferredLanguage: ServerLanguageCode
    termsAndConditionsAcceptedAt: string
    isPhoneVerified: boolean
    sendSms: boolean
    dateOfBirth: string
}

export type EditUserData = {
    email: string
    firstName: string
    lastName: string
    phoneNumber: string
    waNumber?: string
    isWaEnabled: boolean
}

export type StandaloneLoginParams = {
    email: string
    password: string
}

export type TouristData = {
    isTouristUae: boolean
    isTouristKsa: boolean
}
