import {
    convertBusinessHourD2DToPickerTime,
    convertBusinessHourSelfPickupToPickerTime,
} from '@components/global/TimeSelector/utils'
import { useLocaleConfig } from '@contexts/config'
import { availabilityService } from '@service/availability'
import { CityCode } from '@util/enums'
import { minutesToMilliseconds } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

export const useBusinessHours = ({ cityCode, date }: { cityCode?: CityCode; date?: Date }) => {
    const { citySettings } = useLocaleConfig()

    const city = useMemo(
        () => cityCode || (citySettings?.cityCode as CityCode) || CityCode.DXB,
        [cityCode, citySettings?.cityCode],
    )

    const query = useQuery(
        ['getBusinessHoursQuery', city],
        () => availabilityService.getTimeSlotsForBusinessHoursByCity(city),
        {
            staleTime: minutesToMilliseconds(10),
            enabled: !!city,
        },
    )

    const businessHoursForD2D = useMemo(
        () =>
            convertBusinessHourD2DToPickerTime(
                query.data?.data?.d2dAvailability.businessHoursByDayOfWeek,
                date || new Date(),
            ),
        [date, query.data?.data?.d2dAvailability.businessHoursByDayOfWeek],
    )

    const businessHoursForSelfPickup = useMemo(
        () =>
            convertBusinessHourSelfPickupToPickerTime(
                query.data?.data?.selfPickupAvailability.businessHoursByDayOfWeek,
                date || new Date(),
            ),
        [date, query.data?.data?.selfPickupAvailability.businessHoursByDayOfWeek],
    )

    const getBusinessHoursForD2DByDate = useCallback(
        (dateParam?: Date) =>
            convertBusinessHourD2DToPickerTime(
                query.data?.data?.d2dAvailability.businessHoursByDayOfWeek,
                dateParam || new Date(),
            ),
        [query.data?.data?.d2dAvailability.businessHoursByDayOfWeek],
    )

    const getBusinessHoursForSelfPickupbyDate = useCallback(
        (dateParam?: Date) =>
            convertBusinessHourSelfPickupToPickerTime(
                query.data?.data?.selfPickupAvailability.businessHoursByDayOfWeek,
                dateParam || new Date(),
            ),
        [query.data?.data?.selfPickupAvailability.businessHoursByDayOfWeek],
    )

    return {
        query,
        businessHoursForD2D,
        businessHoursForSelfPickup,
        getBusinessHoursForD2DByDate,
        getBusinessHoursForSelfPickupbyDate,
    }
}
