import { createGlobalStyle, css } from 'styled-components'

const rtlCss = css`
    @font-face {
        font-family: 'Vazirtmatn';
        src: url('/rental/Vazirmatn-VariableFont_wght.ttf') format('truetype-variations');
    }

    [dir='rtl'] html,
    [dir='rtl'] body {
        font-family: Vazirtmatn, Geeza Pro, serif;
        direction: rtl;
    }

    [dir='rtl'] .selectedDayFirst {
        background: linear-gradient(
            -90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(62, 62, 94, 0) 50%,
            rgba(255, 90, 90, 0.1) 50%,
            rgba(255, 90, 90, 0.1) 100%
        );
    }

    [dir='rtl'] .selectedDayLast {
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(62, 62, 94, 0) 50%,
            rgba(255, 90, 90, 0.1) 50%,
            rgba(255, 90, 90, 0.1) 100%
        );
    }

    [dir='rtl'] .selectedDayFirst.selectedDayLast {
        background: unset;
    }

    [dir='rtl'] .datepickerTitle {
        justify-content: right;
        padding-right: 24px;
        padding-left: 0;
    }

    [dir='rtl'] .iconSvgArrow {
        transform: rotate(180deg);
    }

    [dir='rtl'] .m-end-5 {
        margin-left: 0.5rem;
        margin-right: 0;
    }

    [dir='rtl'] .m-start-5px {
        margin-right: 5px;
        margin-left: 0;
    }

    [dir='rtl'] .cardItem {
        left: unset;
        right: 5px;
    }

    [dir='rtl'] .priceText {
        unicode-bidi: plaintext;
        display: flex;
        flex-direction: initial;
        align-items: center;
    }

    [dir='rtl'] .priceText b {
        margin-left: 4px;
    }

    [dir='rtl'] .carCard {
        padding: 0 0 0 24px;
    }

    [dir='rtl'] .dateTimeSelectorSpan {
        font-weight: 400;
    }

    [dir='rtl'] .lineHeight {
        line-height: 2;
    }

    [dir='rtl'] .weekdayLabel {
        padding: 24px 15px 12px 15px;
    }

    [dir='rtl'] .recommended {
        right: unset;
        left: 0;
        margin-left: 1rem;
        margin-right: 0;
    }

    [dir='rtl'] .DiscountPlaceholder {
        padding-left: 0;
        padding-right: 16px;
    }

    [dir='rtl'] .iconInsideInput {
        left: 0;
        right: unset;
        margin-right: 0;
        margin-left: 1rem;
    }

    [dir='rtl'] .selectArrowDiv {
        right: unset;
        left: 0;
    }

    [dir='rtl'] .phoneNumber a {
        unicode-bidi: plaintext;
    }

    [dir='rtl'] .addressCardDropDown {
        right: unset;
        left: 0;
    }

    [dir='rtl'] .closeModalSvg {
        right: unset;
        left: 0;
    }

    [dir='rtl'] .addressSuggestionText {
        padding-left: 0;
        padding-right: 16px;
    }

    [dir='rtl'] .addressInput {
        padding: 0 1rem 0 4.5rem;
    }

    [dir='rtl'] .addressInputIcons {
        left: 40px;
        right: unset;
    }

    [dir='rtl'] .modalBackBtn {
        left: unset;
        right: 0;
        transform: rotate(180deg);
    }

    @media (max-width: 450px) {
        [dir='rtl'] .dateTimeSelectorSpan {
            font-size: 10px;
        }
    }
`

export const RTL_Style = createGlobalStyle`${rtlCss}`
