import { defaultCity, defaultCityCode, defaultCountry, defaultServerLanguage } from '@util/config'
import { City, CityCode, Country, CountryCode, LanguageISOCode, LanguageUtsCode, ServerLanguageCode } from './enums'
import { getCityLanguages } from '@hooks/api/configuration'

export type LanguageConfig = {
    serverLanguageCode: ServerLanguageCode
    languageIsoCode: LanguageISOCode
    languageUtsCode: LanguageUtsCode
    name: string
    city: City
    cityCode: CityCode
    countryCode?: CountryCode
    country?: Country
    languageFlag?: string
}

export const DAYS = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
} as const

export const getStartDayOfWeekByCity = (_city: CityCode | string): (typeof DAYS)[keyof typeof DAYS] => {
    return DAYS.SUNDAY
}

export const getPreferredLanguageByLocale = (locale): ServerLanguageCode =>
    LocaleDefinitions.find((language) => language.languageUtsCode === locale)?.serverLanguageCode ??
    defaultServerLanguage

export const getCityCodeByCity = (city: City | string): CityCode =>
    LocaleDefinitions.find((language) => language.city === city)?.cityCode ?? defaultCityCode

export const getCityByCityCode = (city: CityCode | string | undefined): City => {
    if (!city) return defaultCity
    return LocaleDefinitions.find((language) => language.cityCode === city)?.city ?? defaultCity
}

export const getCountryCodeByCity = (city: City | string | undefined): CountryCode => {
    if (!city) return defaultCountry
    return LocaleDefinitions.find((language) => language.city === city)?.countryCode ?? defaultCountry
}

export const getFirstCityOfCountryByCountryCode = (countryCode: CountryCode | string): City =>
    LocaleDefinitions.find((language) => language.countryCode === countryCode)?.city ?? defaultCity

export const getCityCodeByLanguageUtsCode = (utsLanguageCode: LanguageUtsCode | string | undefined): CityCode => {
    if (!utsLanguageCode) return defaultCityCode
    return (
        LocaleDefinitions.find((language) => language.languageUtsCode === utsLanguageCode)?.cityCode ?? defaultCityCode
    )
}

export const getLocaleDefinitionByUtsLanguageCode = (utsLanguageCode: LanguageUtsCode | string): LanguageConfig =>
    LocaleDefinitions.find((language) => language.languageUtsCode === utsLanguageCode) ?? DefaultLocaleDefinition

export const getUtsLanguageCodeByCityCodeAndLanguageIsoCode = (
    cityCode: CityCode,
    languageIsoCode: LanguageISOCode,
): LanguageUtsCode | undefined =>
    LocaleDefinitions.find((language) => language.cityCode === cityCode && language.languageIsoCode === languageIsoCode)
        ?.languageUtsCode

export const getUserLocale = async (userLangCode: ServerLanguageCode, cityParam: string) => {
    const cityLanguages = await getCityLanguages(getCityCodeByCity(cityParam))
    const supportedCityLanguages = cityLanguages.map((lang) =>
        getLocaleDefinitionByUtsLanguageCode(lang as LanguageUtsCode),
    )
    return (
        supportedCityLanguages.find((lang) => lang?.serverLanguageCode === userLangCode)?.languageUtsCode ??
        supportedCityLanguages[0].languageUtsCode
    )
}

export const getCountryByCity = (city: City | string | undefined): Country => {
    if (!city) return Country.UAE
    return LocaleDefinitions.find((language) => language.city === city)?.country ?? Country.UAE
}

export const DefaultLocaleDefinition = {
    serverLanguageCode: ServerLanguageCode.EN,
    name: 'English',
    languageIsoCode: LanguageISOCode.en,
    languageUtsCode: LanguageUtsCode.enDXB,
    city: City.dubai,
    cityCode: CityCode.DXB,
    countryCode: CountryCode.ae,
    country: Country.UAE,
    languageFlag: '🇬🇧',
}

export const LocaleDefinitions: LanguageConfig[] = [
    DefaultLocaleDefinition,
    {
        serverLanguageCode: ServerLanguageCode.AR,
        name: 'Arabic',
        languageIsoCode: LanguageISOCode.ar,
        languageUtsCode: LanguageUtsCode.arDXB,
        city: City.dubai,
        cityCode: CityCode.DXB,
        countryCode: CountryCode.ae,
        country: Country.UAE,
        languageFlag: '🇦🇪',
    },
    {
        serverLanguageCode: ServerLanguageCode.EN,
        name: 'English',
        languageIsoCode: LanguageISOCode.en,
        languageUtsCode: LanguageUtsCode.enRUH,
        city: City.riyadh,
        cityCode: CityCode.RUH,
        countryCode: CountryCode.sa,
        country: Country.KSA,
        languageFlag: '🇬🇧',
    },
    {
        serverLanguageCode: ServerLanguageCode.AR,
        name: 'Arabic',
        languageIsoCode: LanguageISOCode.ar,
        languageUtsCode: LanguageUtsCode.arRUH,
        city: City.riyadh,
        cityCode: CityCode.RUH,
        countryCode: CountryCode.sa,
        country: Country.KSA,
        languageFlag: '🇸🇦',
    },
    {
        serverLanguageCode: ServerLanguageCode.EN,
        name: 'English',
        languageIsoCode: LanguageISOCode.en,
        languageUtsCode: LanguageUtsCode.enAUH,
        city: City.abudhabi,
        cityCode: CityCode.AUH,
        countryCode: CountryCode.ae,
        country: Country.UAE,
        languageFlag: '🇬🇧',
    },
    {
        serverLanguageCode: ServerLanguageCode.AR,
        name: 'Arabic',
        languageIsoCode: LanguageISOCode.ar,
        languageUtsCode: LanguageUtsCode.arAUH,
        city: City.abudhabi,
        cityCode: CityCode.AUH,
        countryCode: CountryCode.ae,
        country: Country.UAE,
        languageFlag: '🇸🇦',
    },
]
